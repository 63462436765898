.picker {
  .picker-label {
    &.error {
      color: $color--error;
    }
  }
}

.privacy,
.terms,
.third_party {
  .group_label {
    label {
      display: inline;
    }
  }
}

.waitlist-iframe-wrapper {
  width: 95%;
  height: 190px;
  margin: 17px 7px 5px 7px;
}

.tiny-waitlist-overlay {
  .waitlist_thankyou_message {
    margin-top: 10px;
  }
  .field {
    .email_comp {
      .email_input {
        float: #{$ldirection};
        width: 79%;
      }
      .align-r {
        float: #{$ldirection};
        margin-#{$ldirection}: 10px;
        .input-btn {
          height: 58px;
        }
      }
    }
  }
}

.site-header li {
  &.site-navigation__item,
  &.site-navigation__more {
    &.is-dropdown,
    &.is-carousel {
      .site-navigation__dropdown,
      .site-navigation__carousel {
        @include swap_direction(margin, -2px 0 0 0);
      }
    }
  }
}

.page--spp__product {
  .product__footer {
    .product__edd {
      display: none;
    }
  }
  .prod_inv_status-2 {
    .product__footer {
      .temp-out-of-stock__msg {
        top: 0 !important;
        @include swap_direction(margin, 0 !important);
      }
    }
  }
  .product__share-bar {
    .product__social-links {
      width: 4em;
    }
  }
}

.lpPoweredBy {
  display: none;
}

.site-footer--top {
  .grid-container--6 .grid--item {
    &:nth-of-type(6n + 1) {
      width: 20%;
    }
    &:nth-of-type(6n + 2) {
      width: 20%;
      @include swap_direction(margin, 0 -100% 0.5em 20%);
    }
    &:nth-of-type(6n + 3) {
      width: 20%;
      @include swap_direction(margin, 0 -100% 0.5em 40%);
    }
    &:nth-of-type(6n + 4) {
      width: 20%;
      @include swap_direction(margin, 0 -100% 0.5em 60%);
    }
    &:nth-of-type(6n + 5) {
      width: 20%;
      @include swap_direction(margin, 0 -100% 0.5em 80%);
    }
  }
}

#email_sms_signup {
  #form--email_sms_signup--field--MOBILE_NUMBER {
    display: none;
  }
}

#product-page-livechat-hitarea {
  #lpButDivID-1468838507818 {
    position: relative;
    top: -1px;
    #{$ldirection}: 0px;
    color: red;
    width: 350px;
  }
}

select::-ms-expand {
  display: none;
}

.select2-container {
  .select2-choice {
    .select2-arrow {
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = '#ffffff', endColorstr = '#eeeeee', GradientType = 0);
    }
  }
}

.site-container {
  #search-wrapper {
    .results-header {
      border-top: none;
    }
  }
  .custom-grid__item {
    .top_five_reasons {
      background: $color--white;
      border-top: 1px solid $color--gray--lightest;
      @include swap_direction(padding, 15px 0 15px 15px);
      position: relative;
      height: 100% !important;
      .text-block__headline {
        @include fontsize-rem(18px);
        @include breakpoint($bp--medium-up) {
          @include fontsize-rem($typesize--24px);
        }
        @include breakpoint($bp--largest-up) {
          @include fontsize-rem(15px);
        }
        @include breakpoint($bp--xxlarge-up) {
          @include fontsize-rem($typesize--24px);
        }
      }
      &_link {
        position: absolute;
        bottom: 10px;
      }
      &_block {
        height: 39%;
      }
    }
    .login_signup {
      &--alternate {
        height: 61%;
        position: relative;
      }
    }
    .trustmark-header {
      .text-block__headline {
        @include fontsize-rem(18px);
        @include breakpoint($bp--medium-up) {
          @include fontsize-rem($typesize--24px);
        }
        @include breakpoint($bp--largest-up) {
          @include fontsize-rem(15px);
        }
        @include breakpoint($bp--xxlarge-up) {
          @include fontsize-rem($typesize--24px);
        }
      }
    }
  }
  .layout--artistry-artistry {
    .twitter-block p {
      letter-spacing: 0.03em;
      line-height: 21px;
    }
  }
}

.site-container {
  #search-wrapper {
    @include breakpoint($bp--xlarge-up $bp--xxlarge-up) {
      .tout-block-landscape__caption {
        .tout-block-landscape__body {
          padding-top: 6px;
          p {
            padding-top: 2px;
            margin-bottom: 0px;
          }
        }
        .tout-block-landscape__headline {
          margin-bottom: 0;
        }
      }
    }
    #zero-results {
      .basic-responsive-v1 {
        p:first-child {
          display: none;
        }
        .tout-block-landscape__caption {
          a.tout-block-landscape__cta {
            width: 218px;
          }
        }
      }
    }
    .search-dimension {
      .results-header {
        margin: 0 0 0.25em 0;
      }
    }
  }
  .content {
    .mac-select__homepage-tout {
      &__ctas {
        bottom: 0;
      }
    }
  }
}

.collection-detail-formatter {
  .grid--mpp__item {
    .product--shaded {
      &.prod_inv_status-2 {
        .product__footer {
          .product__inventory-status {
            .temp-out-of-stock {
              display: block;
            }
          }
        }
      }
    }
  }
}

.loyalty {
  &__panel {
    &__offers {
      &__sku {
        &__list-container {
          display: none;
        }
      }
    }
  }
}
