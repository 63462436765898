.profile-info {
  .withdrawal-link {
    color: $color--gray--light;
  }
}

.mobile_verify {
  .profile_preferences_mobile_pin {
    line-height: 16px;
  }
}

.picker {
  .picker-label {
    float: left;
  }
}

ul.error_messages {
  li {
    color: $errorcolor-text;
  }
}

body.ie {
  .site-header {
    .content {
      .site-logo {
        &__image {
          background-size: auto;
        }
      }
    }
  }
}

.custom-grid__item-inner {
  .site-email-sms-signup {
    height: 61%;
  }
  .site-email-sms-signup__alternate {
    padding-top: 74%;
  }
}

.prod_inv_status-2 {
  .product__footer {
    .product__inventory-status {
      .temp-out-of-stock {
        display: block;
      }
    }
  }
}

.culture-landing__teaser {
  .culture-landing__teaser-title {
    @include breakpoint($bp--largest-up) {
      width: 80%;
    }
  }
}

.site-container {
  .back-to-mac {
    .back-to-mac {
      &__intro {
        ul {
          position: relative;
          li {
            margin-left: 12px;
            margin-top: 12px;
            &:before {
              content: '*';
              position: absolute;
              left: 0;
            }
          }
        }
      }
      &__send_your_package {
        .back-to-mac {
          &__step-desc {
            margin-left: 0;
          }
        }
      }
      &__step {
        .back-to-mac {
          &__step-content {
            .back-to-mac {
              &__btn {
                text-align: center;
                padding: 0 20px;
                height: 58px;
                line-height: 62px;
              }
            }
          }
        }
        &.back-to-mac__shipping_address {
          .back-to-mac__btn {
            margin: 0;
          }
        }
        &__send_your_package {
          .back-to-mac {
            &__send_your_package--column {
              width: 33.33%;
              float: left;
            }
          }
        }
      }
    }
  }
  .order-details-page {
    .order-info__item {
      a {
        pointer-events: none;
        color: inherit !important;
        text-decoration: none;
        border-bottom: none;
      }
    }
  }
  .search-results {
    &__results {
      .grid--mpp {
        &__item {
          .product {
            &__footer {
              padding-top: 0;
            }
          }
        }
      }
    }
  }
}

.sign-in-page {
  .new-account {
    .account_promo {
      label {
        display: inline-block;
      }
      .radio_group {
        .picker-radio {
          display: inline-block;
        }
      }
    }
    .field-container--grid {
      .new-account__item {
        &.checkbox {
          margin-bottom: 5px;
        }
      }
      .pwd_msg {
        padding-bottom: 10px;
        font-size: 12px;
        color: red;
      }
    }
  }
}

.shop-the-collection {
  .product {
    &__footer {
      .product_content_fav_mobile {
        .product {
          &__price {
            font-family: $ano-bold_regular-font;
          }
        }
      }
    }
  }
}

.artist--products {
  .carousel-slide {
    .product {
      &__header {
        .product {
          &__mini-detail {
            position: relative;
            z-index: 1;
          }
        }
      }
    }
  }
}

#site-header {
  .site-header {
    &__tools {
      .site-bag,
      .site-my-mac {
        z-index: 2;
      }
    }
  }
}

.grid--mpp {
  .product {
    &__footer {
      .product {
        &__link-to-spp {
          #sku_count {
            margin-right: -3px;
          }
        }
      }
    }
    &__name {
      font-weight: normal;
      padding-bottom: 5px;
    }
  }
}

.page--spp {
  &__product {
    .view-all-shades {
      &__wrapper {
        .view-all-shades {
          &__text {
            #sku_count {
              margin-right: -5px;
            }
          }
        }
      }
    }
  }
}

.mpp-custom {
  &.mpp-custom--custom-palette {
    .grid--mpp {
      &__item {
        .product {
          &__subline {
            padding-right: 0;
          }
          &__description-short {
            margin-right: 0;
          }
        }
        .product--not-shoppable {
          .product {
            &__detail {
              .product {
                &__link-custom {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.section-makeup-services {
  .content-4col-v1 {
    .content-4col {
      &__column {
        .makeup-service {
          &__content {
            line-height: 2px;
            margin-bottom: 29px;
          }
        }
        .consult-res {
          &__content {
            width: 550px;
          }
        }
      }
    }
  }
}

.grid--mpp__item {
  .product--teaser {
    .product__description-short {
      margin: 3px 20px 3px 0 !important;
    }
    .product__subline {
      padding-right: 25px !important;
    }
  }
}

.site-header {
  li.site-navigation__item,
  li.site-navigation__more {
    @include swap_direction(padding, 0 12px);
    @include breakpoint($bp--xxlarge-up) {
      @include swap_direction(padding, 0 22px);
    }
  }
}

.contact-us-page {
  .form-item {
    &.mobile-phone {
      width: 100%;
      padding-right: 0px;
      p {
        padding-top: 7px;
      }
    }
  }
}

.loyalty_privacy {
  .group_label {
    label {
      display: inline;
    }
  }
}

.product-full {
  &__review-snippet {
    .p-w-r {
      .pr-snippet-stars-reco-inline {
        .pr-snippet {
          .pr-snippet-read-and-write {
            .pr-snippet-review-count {
              &:before {
                content: ' 모든 상품평 읽기 ';
              }
            }
          }
        }
        &.pr-snippet-minimal {
          .pr-snippet-stars-container {
            &:before {
              content: '전체 평점: ';
            }
          }
        }
      }
    }
  }
}

.product-brief-carousel {
  .product-brief {
    &__product-name {
      float: none;
    }
  }
}

#ot-sdk-btn {
  &.ot-sdk-show-settings {
    text-decoration: underline;
  }
}

@if $cr21 == true {
  .cr21-refresh {
    .product-full--enhanced.product-full {
      .product-full__omni-wrapper {
        .bopis-spp-block {
          .bopis-spp-container {
            margin: 0;
          }
        }
      }
    }
  }
}

.privacy-policy {
  color: $color--white;
}

.footer-menu-li {
  display: inline-block;
  height: 25px;
  margin: 0;
}

.site-footer {
  &--bottom {
    .field-menu {
      .last {
        height: 60px;
      }
      .float-none {
        font-size: 1.2rem;
        a {
          text-decoration: underline;
          float: none;
        }
      }
      li {
        display: inline-block;
        margin: 0px;
        height: 25px;
      }
    }
  }
}

.account-loyalty {
  .mac-select__account-panel {
    &__content-details {
      clear: both;
    }
    &__content-processing {
      width: 300px;
      @include breakpoint($bp--medium-landscape) {
        width: 225px;
      }
    }
  }
}
