$color-light-gray: #cccccc;
$background-button: #000000;
$fontcolor-white: #ffffff;
$errorcolor-text: #b84947;
$tier1color: #f7b8d3;
$tier2color: #ed2124;
$tier3color: #acacae;
$color--red--opacity-02: rgba(176, 0, 32, 0.02);

.terms_agree {
  div.legal_statement {
    border: 1px solid $color-light-gray;
    color: $color--black--opacity-54;
    height: 120px;
    line-height: 18px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 5px;
  }
  div.radio_group {
    margin: 8px 0 10px 0;
  }
  .form-item.privacy {
    float: left;
    width: 49%;
    margin-bottom: 25px;
  }
  .form-item.third_party {
    float: left;
    width: 50%;
  }
  .form-item.optional_privacy {
    float: left;
    width: 49%;
    margin-bottom: 25px;
  }
  .form-item.optional_third_party {
    margin-left: 5px;
    float: left;
    width: 50%;
  }
  span.radio {
    float: left;
  }
  .picker {
    .picker-label {
      float: left;
    }
  }
}

#sms_request_pin,
#sms_verify_pin {
  background-color: $background-button;
  padding: 10px;
  color: $fontcolor-white;
  border-radius: 24px;
  height: 54px;
  margin: 10px 0;
}

.mobile__account {
  div.mobile_number_part {
    width: 30%;
    display: inline-block;
    select.selectbox,
    selectbox,
    input {
      width: 100%;
    }
  }
}

.profile-page__content {
  .mobile_profile-page {
    &__head {
      display: none;
    }
  }
  .new-mobile_sms__item {
    margin-top: 15px;
  }
  .birthday_col {
    .select2-container {
      width: 30% !important;
      float: left;
      margin-right: 20px;
    }
    .form_birthday {
      width: 100%;
    }
    .form_title_width {
      label {
        display: block;
      }
    }
  }
  .gender_col {
    .radio {
      width: 25%;
      float: left;
    }
  }
  .form_birthday {
    width: 100%;
    label {
      display: block;
    }
  }
  .profile-info {
    &__header {
      font-size: 18px;
    }
    &__change_pwd_link {
      cursor: pointer;
      line-height: get-line-height(15px, 63.75px);
      text-decoration: underline;
    }
  }
}

.newsletter-info__fieldset {
  .radio_promotions {
    clear: both;
    .picker-radio {
      float: left;
      width: 15%;
    }
    .field_label {
      display: block;
      padding-top: 10px;
    }
  }
}

.margin_top_bottom {
  margin: 10px 0;
}

.other_reason {
  textarea {
    width: 25%;
    height: 20%;
  }
}

#address-lookup-container {
  .address-lookup-wrapper {
    .address-list {
      width: 100%;
      height: 170px;
      border: 1px solid $color-light-gray;
      overflow: scroll;
      padding-top: 10px;
    }
  }
  ul.address-list {
    li.address-item {
      margin: 0 0 10px 10px;
      cursor: pointer;
    }
  }
}

.default-shipping {
  input {
    float: left;
  }
  label {
    padding-left: 20px;
    display: block;
  }
}

.address-book__others {
  .address-item {
    margin-bottom: 20px;
  }
}

.order-return__content {
  .float-right {
    margin: 20px 0;
  }
}

.mobile-info {
  .mobile_number {
    label {
      display: block;
    }
  }
}

.site-container {
  .past-purchases {
    .grid--mpp {
      .product--teaser {
        .product__footer {
          .product-item__out-of-stock {
            top: 30px;
          }
        }
      }
    }
  }
}

.site-container {
  .sidebar-profile-pic {
    .barcode-placeholder {
      margin-top: 20px;
      #barcode {
        width: 155px;
        height: 95px;
      }
    }
  }
  .order-details-page {
    .order-info {
      &__item {
        .order_cancel {
          a {
            pointer-events: auto;
            border-bottom: 1px solid #dbdbdb;
          }
        }
      }
    }
  }
  .order-details-page {
    .order-totals {
      th {
        text-align: right;
        padding-right: 10px;
      }
    }
  }
}

.past-purchases {
  .shipments-list {
    .product {
      &__detail {
        .product {
          &__name {
            padding-right: 0;
          }
        }
      }
    }
  }
}

.address-form-container,
#address_form_container {
  #address-lookup-container {
    padding: 20px;
    fieldset {
      &.address {
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid #000;
      }
    }
    .address-search {
      h4 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
    h3,
    h4 {
      font-size: 12px;
    }
    .address-info {
      margin-bottom: 12px;
    }
    #rna_keyword {
      width: 80%;
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .town-submit-btn {
      vertical-align: middle;
      width: 17%;
    }
    .address-hint-container {
      h4 {
        font-weight: bold;
      }
    }
    .hint-table {
      width: 100%;
      margin: 20px 0px;
      th {
        width: 33.33%;
        padding: 10px 0 10px 15px;
        background: #f7f7f7;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        font-size: 12px;
        font-weight: bold;
      }
      td {
        padding: 10px 0 10px 12px;
        border-bottom: 1px solid #ddd;
        vertical-align: middle;
      }
    }
    #address-results-container {
      table {
        &.rna-list {
          margin: 15px 0;
          th {
            background-color: #f7f7f7;
            padding: 10px 10px 10px 15px;
            vertical-align: middle;
            font-weight: bold;
            text-align: center;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            &.sno {
              width: 50px;
            }
          }
          td {
            &.sno {
              width: 50px;
            }
          }
          th,
          td {
            &.full-address {
              width: 350px;
            }
          }
          th,
          td {
            &.postal {
              width: 95px;
            }
          }
          td {
            padding: 10px 10px 10px 15px;
            border-bottom: 1px solid #ccc;
            vertical-align: middle;
            .roadAddrPart1,
            .roadAddrPart2 {
              display: none;
            }
            .address1 {
              display: block;
              padding: 5px 0;
              cursor: pointer;
            }
            .address3 {
              cursor: pointer;
            }
          }
        }
      }
      tbody {
        max-height: 230px;
        overflow-y: auto;
        overflow-x: hidden;
        display: inline-block;
        width: 100%;
      }
      .search-results-header {
        display: none;
        font-weight: bold;
      }
    }
    #address-third-elements {
      margin-top: 10px;
      h4 {
        font-size: 14px;
        font-weight: bold;
        padding-bottom: 8px;
      }
      .address-details {
        background-color: #f7f7f7;
        padding: 10px;
        margin: 0 5px 15px;
        .street,
        .detail-address {
          margin: 10px;
        }
        label {
          width: 20%;
          display: block;
          float: left;
          font-weight: bold;
        }
        #addr2_input_node {
          width: 75%;
        }
        .zip {
          display: none;
        }
        .building {
          margin: 0 auto;
          width: 57%;
          .address-search-h {
            margin: 5px 0;
          }
        }
      }
      .address-submit-button {
        width: 50%;
        margin: 0 auto;
      }
    }
    #rna-loading,
    #rna-page-list {
      text-align: center;
    }
    .error-display {
      background-color: #f7f7f7;
      text-align: center;
      margin: 15px 0;
      .error_messages,
      .error-msg {
        color: #000;
        display: none;
        padding: 20px;
      }
    }
    #rna-page-list {
      .pagelist {
        cursor: pointer;
      }
    }
  }
}

#colorbox {
  #cboxWrapper {
    #address_form_container {
      #address-lookup-container {
        .address-search {
          width: 100%;
        }
      }
    }
  }
}

.order-return {
  &__content {
    #return-info-wrapper {
      #return-address {
        .edit-address {
          font-size: 18px;
          margin-left: 8px;
        }
      }
    }
  }
}

.gift_popup {
  #cboxLoadedContent {
    background: black;
    color: #fff;
  }
  .icon--remove:before {
    color: #fff;
  }
}

div.loyalty_gift {
  color: #fff;
  text-decoration: none;
  text-align: center;
  .popup_gift_content {
    font-size: 18px;
  }
  .remind_me_later {
    font-size: 20px;
    margin-top: 87px;
  }
  .picker-checkbox.picker .picker-flag:before {
    margin-bottom: 10px;
    color: #fff;
  }
  .picker {
    .picker-label span.label-content {
      color: #fff;
    }
    .picker-handle:hover {
      border-color: #fff;
    }
  }
}

.account-loyalty {
  .mac-select__progress {
    .mac-select__progress-label {
      font-size: 1.1rem;
      white-space: normal;
      line-height: 1.5;
    }
    .mac-select {
      &__swatch {
        &-tier1 {
          background-color: $tier1color;
          background-image: none;
        }
        &-tier2 {
          background-color: $tier2color;
          background-image: none;
        }
        &-tier3 {
          background-color: $tier3color;
          background-image: none;
        }
      }
    }
  }
  .mac-select {
    &__color {
      &-tier1 {
        color: $tier1color;
      }
      &-tier2 {
        color: $tier2color;
      }
      &-tier3 {
        color: $tier3color;
      }
    }
  }
}

.optional_privacy {
  .group_label {
    label {
      display: inline;
    }
  }
}

.optional_third_party {
  .group_label {
    label {
      display: inline;
    }
  }
}

.checkout,
.sign-in-page,
.profile-page,
.address__content,
.address-form {
  .adpl {
    .mobile_number,
    .birthday_col,
    .default-shipping {
      label {
        display: block;
      }
    }
    .group_label {
      label {
        display: inline-block;
      }
    }
    label {
      display: none;
    }
    input.error {
      border-color: $color--red;
    }
    .picker-radio,
    .picker-checkbox {
      label {
        display: block;
      }
    }
    .form-item,
    .required-message {
      margin-bottom: 15px;
    }
    .profile-info {
      .profile-info__required {
        @include swap_direction(margin, 0 0 20px 0);
        display: block;
      }
      .error_messages {
        @include swap_direction(margin, 0 0 20px 0);
      }
    }
    .gift-message {
      label {
        display: block;
      }
    }
  }
  .error_messages {
    @include swap_direction(margin, 0 0 20px 0);
  }
}

#address-form {
  select {
    height: auto;
  }
  .phone {
    height: 60px;
  }
}

.address-form {
  .address_field_heading.full_name {
    display: none;
  }
  .address_field_heading.address {
    @include swap_direction(margin, 0 0 10px 0);
  }
}

@media (min-width: 641px) {
  .profile-pic-main {
    .sidebar-shades {
      display: none;
    }
  }
}

#loyalty__content,
.loyalty__content-bottom,
.loyalty-select__bottom,
.site-my-mac__contents-footer-title {
  .mac-select {
    &__swatch {
      &-tier1 {
        background-color: $tier1color;
        background-image: none;
      }
      &-tier2 {
        background-color: $tier2color;
        background-image: none;
      }
      &-tier3 {
        background-color: $tier3color;
        background-image: none;
      }
    }
    &__color {
      &-tier1 {
        color: $tier1color;
      }
      &-tier2 {
        color: $tier2color;
      }
      &-tier3 {
        color: $tier3color;
      }
    }
    &__marketing-page {
      .loyalty {
        &__content-bottom {
          &__section-container-header {
            text-align: $ldirection;
            line-height: 1;
            width: 60%;
            font-size: 40px;
            .shop {
              &__sub-header {
                font-size: 20px;
                line-height: 1;
                &-text {
                  margin-top: 5px;
                  margin-bottom: 0;
                }
              }
            }
          }
        }
        &__benefits-container {
          .section-right {
            .section-level {
              &-two,
              &-three,
              &-four {
                width: 33.33%;
                .section-container {
                  .section-items {
                    .section-listitems {
                      height: 65px;
                      @media screen and (max-width: $width-xxlarger) {
                        &:nth-child(2) {
                          height: 105px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .section-left {
            .section-container {
              .section-items {
                .section-listitems {
                  padding-top: 10px;
                  text-align: center;
                  font-size: 12px;
                  height: 65px;
                  &:nth-child(7),
                  &:nth-child(8) {
                    padding-top: 25px;
                    text-align: center;
                    height: 65px;
                  }
                  @media screen and (max-width: $width-xxlarger) {
                    &:nth-child(2) {
                      height: 105px;
                    }
                  }
                  .section-title {
                    font-weight: 700;
                  }
                }
              }
            }
          }
        }
      }
      #terms-container {
        margin-top: 20px;
      }
    }
  }
}

.loyalty__content-bottom {
  &__section-container-header {
    width: 75%;
  }
  .macselect-benefits {
    &__container {
      .macselect-benefits {
        &__section-fields {
          width: 58%;
        }
        &__section-levels {
          width: 42%;
        }
      }
    }
  }
}

.macselect-benefits {
  position: relative;
  &__joinnow {
    img {
      margin: 0 auto;
    }
    > a {
      position: absolute;
      left: 41.17%;
      top: 77.81%;
      width: 17.81%;
      height: 5.72%;
      z-index: 1;
      @include unset-underline-links($extend: false);
    }
  }
  &__disclaimer {
    border-top: $border;
    padding: 12px;
    margin: 6px 0;
  }
  &__section-levels__header,
  &__section-title {
    text-align: center;
  }
}

#loyalty__content {
  .loyalty__panel {
    &__offers {
      &__sku {
        &__header__title {
          font-size: 3rem;
        }
        &__header__subtitle {
          white-space: nowrap;
        }
        &__product {
          max-width: 351px;
          &__image {
            margin: 0 auto;
          }
        }
        &__list {
          margin: 0 auto;
          &-container {
            margin: 15px 0;
            .tabbed-rewards-block__content {
              max-width: 1279px;
              margin: 0 auto;
            }
          }
        }
      }
    }
    &__history__table {
      .loyalty__panel__transactions {
        &__table,
        &__paginate {
          border: none;
        }
        &__list__column {
          &-date {
            width: 20%;
          }
          &-activity {
            width: 40%;
            &__transactions {
              display: block;
            }
            &__transaction-link {
              border: none;
            }
          }
          &-points {
            width: 25%;
          }
          &-total {
            width: 15%;
          }
        }
      }
    }
  }
}

.loyalty-select__bottom {
  margin: 90px auto;
}

.loyalty__page__index {
  &-2col {
    border-bottom: none;
  }
}

.progress_bar {
  @if $fonts_update == false {
    .progress_bar_left--header {
      font-size: 2.5rem;
    }
  }
  .progress_bar_text {
    #{$ldirection}: 34%;
    text-align: center;
    width: 28%;
  }
  .points__expiration {
    left: 28%;
  }
  .progress_bar_container {
    width: 230px;
    height: 230px;
    margin-#{$rdirection}: 12px;
    canvas {
      width: 100%;
      height: 100%;
    }
  }
}

.mac-lover__events__promos--header {
  font-size: 36px;
  width: 60%;
}

.mac-select__marketing-page {
  &__title {
    white-space: nowrap;
  }
  &__romance-header {
    width: 50%;
  }
  .loyalty__content-bottom {
    &__section-container-shade {
      left: -24%;
    }
  }
}

.loyalty__panel__offers__sku__header__subtitle {
  white-space: nowrap;
}

#signin {
  .sign-in-page {
    .select2-container {
      .select2-choice {
        height: 54px;
        & > .select2-chosen {
          margin: 0 10px;
        }
      }
    }
  }
  .site-container {
    .sign-in-page {
      .picker-checkbox {
        .naver_error {
          a {
            color: $errorcolor-text;
          }
        }
      }
    }
    .birth_container {
      .birth_title {
        font-size: 15px;
        padding-bottom: 10px;
      }
      .birthday_col {
        label {
          display: block;
          padding-bottom: 5px;
        }
        .form_birthday {
          .field {
            border-color: $color-light-gray;
          }
          .selectbox {
            margin-#{$ldirection}: 10px;
            margin-#{$rdirection}: 0;
          }
        }
      }
      .radio {
        display: inline-flex;
        .picker {
          .picker-handle {
            @include swap_direction(margin, 0 5px 0 3px);
          }
        }
      }
    }
  }
}

.social-login {
  &__container {
    .opt-in {
      &__container {
        text-align: $ldirection;
        .group_label {
          label {
            display: inline;
          }
        }
        .radio {
          &.field {
            margin-#{$ldirection}: 36px;
            .picker {
              display: inline-block;
            }
          }
        }
        .naver_error {
          a {
            color: $errorcolor-text;
          }
        }
      }
    }
    .picker-radio {
      &.picker {
        &.checked {
          .picker-handle {
            .picker-flag {
              margin: 2px;
            }
          }
        }
      }
    }
  }
  &__button {
    margin-top: 25px;
    img {
      width: 90%;
      border-radius: 24px;
    }
    .kakao_img {
      height: 84px;
    }
  }
  .error_messages {
    display: none;
    background: $color--red--opacity-02;
    border-radius: 4px;
    border: 1px solid $color-dark-red;
    padding: 10px;
    width: auto;
    margin: 10px 0;
  }
}
