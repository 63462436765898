#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          .pr-rd-review-header-sorts {
            .pr-rd-sort-group {
              .pr-rd-sort {
                background: url(/media/dev/icons/src/arrow--down.svg) 95% 45% / 10px no-repeat;
              }
            }
          }
        }
      }
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: '예(';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: '아니오(';
              }
            }
          }
          .pr-rd-flag-review-container {
            margin-right: 12px;
            line-height: 1.5;
          }
        }
      }
      .pr-rd-main-footer {
        .pr-rd-content-block {
          .pr-rd-to-top {
            padding-left: 39%;
          }
        }
      }
    }
  }
}

#power_review_container {
  .email_check {
    h3 {
      letter-spacing: 0;
    }
  }
}

.write_a_review {
  &__container {
    #pr-write {
      .pr-war {
        .pr-submit {
          .pr-footer {
            .pr-subscript {
              display: none;
            }
          }
        }
      }
    }
  }
}
