.checkout__content {
  .cart-header__title--total {
    width: 15.333%;
    @include swap_direction(margin, 0 -100% 0 80.666%);
  }
  .shipping-info {
    .address_controls {
      .edit-address {
        display: inline !important;
      }
    }
  }
  #viewcart-panel {
    .cart-item__total {
      width: 15.333% !important;
      @include swap_direction(margin, 1em -100% 0.5em 80.666%);
    }
  }
  .order-summary__content {
    .value {
      width: 15.33333%;
      @include swap_direction(margin, 0 -100% 1em 80.666%);
    }
    .total {
      &.label {
        margin-top: 21px;
      }
      &.value {
        position: relative;
        top: 81px;
        white-space: nowrap;
      }
    }
  }
  #bottom-viewcart-buttons,
  #top-viewcart-buttons {
    .continue-buttons {
      @include swap_direction(padding, 0 6% 0 0);
      text-align: $rdirection;
    }
  }
  #recommended-products-panel {
    .recommended-product-items {
      .info .formatted_price {
        position: relative;
        bottom: inherit;
      }
    }
  }
  .signin-to-see-cart a {
    color: $color--red;
    font-weight: bold;
  }
}

.viewcart {
  .checkout-header {
    #top-viewcart-buttons .continue-buttons {
      text-align: $rdirection;
      @include swap_direction(padding, 0 6% 0 0);
    }
  }
}

#delivery-options-container {
  .address-info {
    float: left;
    width: 47%;
    min-height: 50px;
    @include swap_direction(margin, 0 2% 0 0);
    .error_messages,
    .error_messages a {
      color: $errorcolor-text;
    }
  }
  .address_controls {
    .selectbox {
      width: 50% !important;
    }
  }
}

.gift-message {
  #to {
    width: 49%;
    float: left;
    margin-right: 15px;
  }
  #from {
    width: 49%;
    float: left;
  }
  .form-item {
    position: static;
  }
}

#colorbox {
  #cboxContent {
    #address-lookup-container {
      .address-search {
        width: 94%;
      }
    }
  }
}

.review {
  #review-instructions {
    margin-top: 20px;
  }
  #checkout-buttons {
    &.naver_pay_btn {
      background: transparent center/94% 100% no-repeat url('/media/export/cms/checkout/naver_btn.png');
      color: transparent;
      width: 150px;
      height: 40px;
      border: none;
    }
  }
}

#review-address-info {
  .trans_detail_item {
    width: 33%;
    float: left;
    padding: 0 10px;
  }
  .change_link {
    float: right;
    margin-right: 10%;
  }
  address {
    clear: both;
  }
  .trans_header {
    border-bottom: 1px solid $color-light-gray;
    margin-bottom: 15px;
  }
}

p.escrow {
  display: none;
}

address {
  font-style: normal;
}

#confirmation-panel {
  .checkout-header__title {
    width: 100%;
    right: 0;
    text-align: center;
    padding-right: 0;
  }
}

.checkout_confirm {
  .account-nav__section {
    display: none;
  }
  .site-container {
    min-height: auto;
    min-width: auto;
  }
  .checkout__sidebar {
    margin-bottom: 20px;
    .links-panel {
      margin-top: 1em !important;
    }
  }
}

#gift-wrap-img {
  width: 100px;
}

.continue-buttons {
  .disabled.btn {
    background-color: $color-light-gray;
    text-decoration: none;
    cursor: not-allowed;
  }
  .continue-checkout,
  .go-shopping {
    text-decoration: none;
  }
}

#giftwrap-info-panel {
  #gift_box--terms {
    color: #888;
  }
}

.checkout {
  &__content {
    #order-summary-panel {
      .order-summary {
        &__content {
          .label {
            &.subtotal,
            &.shipping {
              text-align: right;
              width: 90px;
            }
            &.total {
              padding-left: 12px;
            }
          }
        }
      }
    }
    .recommended-products-panel {
      .recommended-item {
        position: relative;
        text-align: center;
        &__button {
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
    }
    #viewcart-panel {
      .cart-item {
        .cart-item {
          &__price--mobile-only {
            display: none;
          }
        }
      }
    }
  }
}

.checkout {
  &__sidebar {
    #need-help-panel {
      .links_list {
        .phone {
          margin-bottom: 2px;
        }
        .mobile_hidden {
          margin: 0;
        }
      }
    }
    #order-summary-panel {
      .label {
        &.order-summary {
          &__subtotal-label,
          &__total-label {
            width: 88px;
            text-align: right;
          }
        }
      }
    }
  }
  #viewcart-panel {
    .cart-items {
      .cart-item {
        &.kit {
          .cart-item {
            &__qty {
              height: 40px;
              line-height: 30px;
              padding-left: 26px;
            }
          }
        }
      }
    }
  }
}

.viewcart {
  .checkout-header {
    .checkout-header {
      &__title {
        width: 100%;
        padding-right: 0;
        text-align: center;
        right: auto;
        padding-left: 0;
      }
    }
    #top-viewcart-buttons {
      width: 100%;
      .viewcart-buttons-panel {
        .continue-buttons {
          text-align: center;
          padding: 0;
          width: 100%;
        }
      }
    }
  }
  #bottom-viewcart-buttons {
    .continue-buttons {
      padding-right: 3px;
      padding-left: 0;
      width: 80%;
    }
  }
}

#address-form {
  select {
    height: auto;
  }
}

#cboxContent {
  #address-confirmation {
    text-align: center;
    padding: 18px;
    h2 {
      font-size: 16px;
      margin-bottom: 25px;
    }
    .change-address-content {
      font-size: 13px;
    }
    .address-confirm-buttons {
      margin-top: 20px;
      .address-confirm,
      .address-cancel {
        width: 40%;
        display: inline-block;
        margin-left: 10px;
        .btn {
          width: 100%;
        }
      }
    }
  }
}

#bottom-viewcart-buttons {
  .continue-buttons {
    .review-panel__loyalty-points__description {
      font-size: 18px;
      @if $fonts_update == false {
        font-family: $ano-bold_regular-font;
      }
    }
  }
}
